import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs"
import DeveloperSlider from "@components/DeveloperSlider/DeveloperSlider"
import FloatingCTAShell from "@components/FloatingCTAShell/FloatingCTAShell"
import Layout from "@components/Layout/Layout"
import TeamInfo from "@components/TeamInfo/TeamInfo"
import TeamProperties from "@components/TeamProperties/TeamProperties"
import MailIcon from "@components/icons/MailIcon"
import MobileIcon from "@components/icons/MobileIcon"
import WhatsAppIcon from "@components/icons/WhatsAppIcon"
import Seo from "@components/seo"
import { aboutURL, teamsURL } from "@lib/urls"
import { addRollWhatsapp, getWhatsAppURL, gtagReportConversion } from "@lib/utils"
import "@styles/team-detail.scss"
import { Link, graphql, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
const  {GetTransformedUrl} = require("@starberry/gatsby-theme-utils/Common/Stb-Jamstack-Ggfxclient/Client")
const TeamDetailTemplate = ({ data }) => {
  const team = data.strapiTeam
  const properties = data.allProperties?.nodes
  const noNegotiaterProperties = data.noNegotiater?.nodes

  // if (!team) {
  //   navigate("/404/")
  // }

  const { name, phone, slug, email, negotiator_mapping, category } = team

  const breadcrumbURLs = [
    {
      url: aboutURL,
      label: "About",
    },
    {
      url: teamsURL,
      label: "Meet the Team",
    },
    {
      url: "",
      label: name,
    },
  ]
  const isManagement = category?.strapi_json_value?.includes("Management")
  useEffect(() => {
    if (sessionStorage.getItem("searchpath") && typeof window !== "undefined") {
      sessionStorage.removeItem("searchpath");
    }
  }, []);
  return (
    <>
      <Layout
        pageData={{
          page_class: "team-detail-page",
          layout: "team-detail",
        }}
      >
        <Breadcrumbs staticURLs={breadcrumbURLs} />
        <Container className="team-detail-container">
          <TeamInfo team={team} />
        </Container>
        <DeveloperSlider
          module={{ heading: "Explore our off plan developers" }}
        />
        <TeamProperties
          team={team}
          properties={properties}
          noNegotiaterProperties={noNegotiaterProperties}
        />
      </Layout>
      {!isManagement &&
      <FloatingCTAShell>
        {/* {email && (
          <Link
            to={`${teamsURL + slug}/contact/`}
            className="button button-orange"
          >
            <span>Email</span>
            <MailIcon color="white" />
          </Link>
        )} */}
        <a
          href={`tel:${phone}`}
          className="button button-gray"
          onClick={() => gtagReportConversion(`tel:${phone}`)}
        >
          <span>Call</span>
          <MobileIcon />
        </a>
        <a
              href={getWhatsAppURL("971505389860",
                `Hello Provident,\n\nI would like to contact ${name}\n\n• Email: ${email}\n• Link: ${process.env.GATSBY_SITE_URL + teamsURL+slug}\n• ID: ${negotiator_mapping}\n\nModifying this message will prevent it from being sent to the agent.`,
                 email, phone, name)}
              className="button button-gray"
              target="_blank"
              rel="noreferrer"
              onClick={()=> addRollWhatsapp()}
              >
              <span>WhatsApp</span>
              <WhatsAppIcon />
            </a>
      </FloatingCTAShell>}
    </>
  )
}

export default TeamDetailTemplate

export const Head = ({ data }) => {
  const pageData = data?.strapiTeam;
  let myimgtransforms = ""
  if (pageData?.imagetransforms?.image_Transforms) {
    myimgtransforms = JSON.parse(
      pageData?.imagetransforms?.image_Transforms
    )
  }

  let myimgtransforms1 = ""
  if (pageData?.imagetransforms?.metaog_Transforms) {
    myimgtransforms1 = JSON.parse(pageData?.imagetransforms?.metaog_Transforms)
  }

  let getImg=pageData?.image?.url?pageData?.image?.url:""
  const findImage = getImg?.substring(getImg?.indexOf("i/"));
  let imageExtension=getImg?getImg?.split("."):""
  let ext=imageExtension?.length>0?imageExtension?.pop():""
  

  let imageUrl=""

  if(myimgtransforms1){
    const ProcessedResult=findImage&&myimgtransforms1[findImage]?myimgtransforms1[findImage][ext]:""
    imageUrl=ProcessedResult?ProcessedResult["336x"]:getImg
  }

  else if(myimgtransforms){
    // ogRender()
    const findImage = pageData?.image?.url?.substring(pageData?.image?.url?.indexOf("i/"));
    const ProcessedResultWebp=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage]["webp"]:""
    const ProcessedResultExt=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage][ext]:""
    const ProcessedResult=ProcessedResultExt?ProcessedResultExt["336x"]:ProcessedResultWebp?ProcessedResultWebp["336x"]:pageData?.image?.url
    imageUrl=ProcessedResult
  }

  let pageImg = ""
  if (Object.keys(myimgtransforms)?.length > 0) {
    let pageImg_filt = []
    for (const myimgtransform in myimgtransforms) {
      if (typeof myimgtransforms[myimgtransform]["webp"] !== "undefined") {
        pageImg_filt = Object.values(myimgtransforms[myimgtransform]["webp"])
      }
      if (pageImg_filt?.length > 0) pageImg = pageImg_filt?.length>0?pageImg_filt[0]:pageData?.image?.url
    }
  }
   

  //new ggfx image
  const getImage =
  pageData?.image?.url
      ? pageData?.image?.url.replace(
          "https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/",
          "",
        )
      : ""

  const filterImage =
    getImage && pageData?.ggfx_results?.length > 0
      ? pageData?.ggfx_results?.filter((item) => item.src_cftle === getImage)
      : ""
  
  // filter image with image size
  const getOGImg =[]
    filterImage?.length > 0 && filterImage?.map(item=>{
      if(item?.transforms?.length>0){
         item.transforms.filter((trans) => {
            if(trans?.transform === "600ct350"&&trans?.format===ext){
              getOGImg.push(trans)
            }
         })
        
      }
    } )

   //to get image in uploaded format   
  const ogRenderNew = ()=>{
    GetTransformedUrl({
      srcCftle: findImage,
      format: ext,
      transform:"600ct350",
      id: pageData.strapi_id,
      field: "metaog",
      contentType: "team"
    })
  }

  useEffect(()=>{
    if(!getOGImg?.length>0){
        ogRenderNew()
    }
  },[]) 

  //----
  const pageImage=getOGImg?.length>0?getOGImg[0].url : pageImg? pageImg : pageData?.extra?.profile_image ? pageData?.extra?.profile_image : imageUrl

  return (
    <Seo
      title={`Meet | ${pageData?.name} | ${pageData?.designation}`}
      description={`Meet ${
        pageData?.name
      }, a dedicated and experienced member of the Provident Estate team. Learn more about ${
        pageData?.name?.split(" ")?.[0]
      }'s expertise, accomplishments here. Contact today!`}
      customCanonical={pageData?.seo?.canonicalURL}
      img={pageImage}
    />
  )
}

export const query = graphql`
  query ($id: String, $negotiator_id: String) {
    strapiTeam(id: { eq: $id }) {
      ...teamFragment
    }

    allProperties(
     sort: { fields: createdAt, order: DESC }
      filter: { crm_negotiator_id: {brn_number: {eq: $negotiator_id}}, publish: { eq: true }}
      #filter: { negotiator_id: { ne: $negotiator_id }, publish: { eq: true } }
    ) {
      nodes {
        ...propertyFragment
      }
    }

    noNegotiater: allProperties(
    sort: { fields: createdAt, order: DESC }
      filter: { crm_negotiator_id: {brn_number: {eq: null}}, publish: { eq: true }}
      #filter: { negotiator_id: { eq: null }, publish: { eq: true } }
    ) {
      nodes {
        ...propertyFragment
      }
    }
  }
`
